exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bank-onboarding-form-index-tsx": () => import("./../../../src/pages/bank-onboarding-form/index.tsx" /* webpackChunkName: "component---src-pages-bank-onboarding-form-index-tsx" */),
  "component---src-pages-bank-onboarding-form-thank-you-tsx": () => import("./../../../src/pages/bank-onboarding-form/thank-you.tsx" /* webpackChunkName: "component---src-pages-bank-onboarding-form-thank-you-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-contact-us-thank-you-tsx": () => import("./../../../src/pages/contact-us/thank-you.tsx" /* webpackChunkName: "component---src-pages-contact-us-thank-you-tsx" */),
  "component---src-pages-how-much-i-can-save-tsx": () => import("./../../../src/pages/how-much-i-can-save.tsx" /* webpackChunkName: "component---src-pages-how-much-i-can-save-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-the-pilot-index-tsx": () => import("./../../../src/pages/join-the-pilot/index.tsx" /* webpackChunkName: "component---src-pages-join-the-pilot-index-tsx" */),
  "component---src-pages-join-the-pilot-thank-you-tsx": () => import("./../../../src/pages/join-the-pilot/thank-you.tsx" /* webpackChunkName: "component---src-pages-join-the-pilot-thank-you-tsx" */),
  "component---src-pages-pitch-[type]-tsx": () => import("./../../../src/pages/pitch/[type].tsx" /* webpackChunkName: "component---src-pages-pitch-[type]-tsx" */),
  "component---src-pages-playground-tsx": () => import("./../../../src/pages/playground.tsx" /* webpackChunkName: "component---src-pages-playground-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-support-ticket-ticket-id-tsx": () => import("./../../../src/pages/support/ticket/[ticketID].tsx" /* webpackChunkName: "component---src-pages-support-ticket-ticket-id-tsx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-api-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/api.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-api-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-bank-accepting-payments-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/bank/accepting-payments.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-bank-accepting-payments-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-bank-api-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/bank/api.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-bank-api-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-bank-authentication-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/bank/authentication.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-bank-authentication-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-bank-linking-account-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/bank/linking-account.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-bank-linking-account-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-bank-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/bank.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-bank-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-bank-payment-updates-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/bank/payment-updates.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-bank-payment-updates-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-bank-schemas-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/bank/schemas.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-bank-schemas-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-payments-flow-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/payments-flow.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-payments-flow-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-schemas-currencies-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/schemas/currencies.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-schemas-currencies-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-schemas-customer-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/schemas/customer.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-schemas-customer-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-schemas-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/schemas.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-schemas-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-schemas-payment-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/schemas/payment.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-schemas-payment-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-schemas-payment-states-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/schemas/payment-states.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-schemas-payment-states-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-schemas-scopes-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/schemas/scopes.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-schemas-scopes-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-schemas-transaction-channel-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/schemas/transaction-channel.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-schemas-transaction-channel-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-support-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/support.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-support-mdx" */),
  "component---src-templates-docs-tsx-content-file-path-src-content-documentation-transactions-mdx": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/balwant/kitchen-sink/numero-pay/website/src/content/documentation/transactions.mdx" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-src-content-documentation-transactions-mdx" */)
}

